.e-list_loyalty {
  width: 27%;
  padding: 35px 40px 9px 51px;
}

.e-list_loyalty .loyalty-offer__header {
  margin-top: 10px;
}

.e-list_loyalty .loyalty-offer__link-container {
  margin-top: 10px;
}
